import React, { useEffect } from "react";
import "./styles.css";
import newsOne from "../../asset/news-1.jpg";
import newsTwo from "../../asset/news-2.jpg";
import newsThree from "../../asset/news-3.jpg";
import heroTwo from "../../asset/strategies_1.jpg";
import gridOne from "../../asset/strategies_1.jpg";
import gridTwo from "../../asset/strategies_2.jpg";
import gridThree from "../../asset/strategies_3.jpg";
import gridFour from "../../asset/strategies_4.jpg";
import { GoShieldCheck } from "react-icons/go";
import { FcHome } from "react-icons/fc";
import { FaBitcoin } from "react-icons/fa";
import { FaMoneyBillAlt } from "react-icons/fa";
import { Footer, Header, Loading, PageHero } from "../../components";
import { heroText } from "./constants";
import { Motion, MotionRight, MotionX } from "../../components/Motion";
import { useGlobalContext } from "../../context/context";

const Home = () => {
  const { getAllWithdrawalss } = useGlobalContext();
  const name = "Ogidi Finance";

  useEffect(() => {
    getAllWithdrawalss();
  }, []);
  return (
    <>
      <Header />

      <section className="home_page_wrapper">
        <PageHero
          headerTitle={"Ogidi Finance"}
          btnTitle={"Create an account"}
          text={heroText}
        />
        <div className="home_sect_hero_sect">
          <div className="home_about_image_sect">
            <Motion>
              <img src={heroTwo} alt="" />
            </Motion>
          </div>
          <div className="home_about_text_sect">
            <Motion>
              <p className="why_choose_p_text">About us</p>
            </Motion>
            <MotionRight>
              <h1>{name}</h1>
            </MotionRight>
            <MotionRight>
              <p className="home_about_sect_last_p">
                {name} continues to serve the financial needs of individuals,
                farmers, businesses, and industries by offering the traditional
                banking products, as well as online, mobile and telephone
                banking products.
              </p>
            </MotionRight>
            <MotionRight>
              <p className="home_about_sect_last_p">
                Take the first step towards a brighter financial future with{" "}
                {name}. Contact us today to schedule a consultation with one of
                our financial experts or explore our website to learn more about
                our services and how we can help you achieve your goals
              </p>
            </MotionRight>
            <MotionRight>
              <p className="home_about_sect_last_p">
                With our user-friendly online platform, managing your finances
                has never been easier. From loan applications to account
                management and investment tracking, enjoy seamless access to
                your financial information whenever and wherever you need it.
              </p>
            </MotionRight>
          </div>
        </div>
        <div className="home_service_sect">
          <MotionX>
            <p className="why_choose_p_text">Our Services</p>
          </MotionX>
          <Motion>
            <h1>
              <span>Best Solutions</span> For Your Business
            </h1>
          </Motion>
          <div className="solutions_sect_wrapper">
            <div>
              <Motion>
                <GoShieldCheck size={50} color="var(--pri-clr)" />{" "}
              </Motion>
              <MotionX>
                <h3>Secure Transactions</h3>
              </MotionX>
              <MotionX>
                <p>
                  Very secured and supervised means to save, invest and transfer
                  your funds at full guarantee.
                </p>
              </MotionX>
            </div>
            <div>
              <MotionRight>
                <FaMoneyBillAlt size={50} color="var(--pri-clr)" />{" "}
              </MotionRight>
              <MotionX>
                <h3>Quick Loans</h3>
              </MotionX>
              <Motion>
                <p>
                  We have great loan offers to help meet your needs with very
                  low interest rates. Also available to every one regardless of
                  your status.
                </p>
              </Motion>
            </div>
            <div>
              <MotionRight>
                <FaBitcoin size={50} color="var(--pri-clr)" />{" "}
              </MotionRight>
              <Motion>
                <h3>Digital Assets</h3>
              </Motion>
              <MotionX>
                <p>
                  Digital assets, like bitcoin, are an emerging asset class for
                  investors.Our digital asset fund offers investors exposure to
                  bitcoin and other assets.
                </p>
              </MotionX>
            </div>
            <div className="solutions">
              <Motion>
                <FcHome size={50} color="var(--pri-clr)" />{" "}
              </Motion>
              <MotionX>
                <h3>Real Estate</h3>
              </MotionX>
              <MotionRight>
                <p>
                  Invest in the Real Estate Industry, the Fastest and largest
                  income generator. {name} gives you this superior and unique
                  edge.
                </p>
              </MotionRight>
            </div>
          </div>
        </div>
        <div className="home_strategies_sect">
          <MotionX>
            <h1>
              See Our <span>Successful Strategies</span>
            </h1>
          </MotionX>
          <div className="strategies_grid_sect">
            <img
              src={gridTwo}
              alt=""
              className="strategies_grid_sect_image_1"
            />
            <img
              src={gridOne}
              alt=""
              className="strategies_grid_sect_image_2"
            />
            <img
              src={gridThree}
              alt=""
              className="strategies_grid_sect_image_3"
            />
            <img
              src={gridFour}
              alt=""
              className="strategies_grid_sect_image_4"
            />
          </div>
        </div>
        <div className="home_why_choose_sect">
          <div className="home_why_choose_sect_item_one">
            <MotionX>
              <p className="vision_text">
                The mission of {name} is to contribute to the sustainable
                development of the international banking sector by providing.
                Below are our core values
              </p>
            </MotionX>
            <Motion>
              <h2>Our Values</h2>
            </Motion>
            <Motion>
              <h3>INTEGRITY AND HONESTY</h3>
            </Motion>
            <MotionX>
              <h3>LOYALTY</h3>
            </MotionX>
            <MotionX>
              <h3>TEAM WORK</h3>
            </MotionX>
            <Motion>
              <h3>COMMUNITY</h3>
            </Motion>
            <MotionX>
              <h3>ACCOUNTABILITY</h3>
            </MotionX>
            <MotionRight>
              <h3>EXCELLENCE</h3>
            </MotionRight>
            <MotionRight>
              <h3>CONFIDENTIALITY</h3>
            </MotionRight>
            <MotionRight>
              <h3>RELATIONSHIP</h3>
            </MotionRight>
          </div>
          <div className="home_why_choose_sect_item_two">
            <MotionRight>
              <p className="why_choose_p_text">Why choose us</p>
            </MotionRight>
            <Motion>
              <h1>
                <span>Your Success</span> Means a Lot To Us
              </h1>
            </Motion>
            <MotionRight>
              <h3>Our Vision</h3>
            </MotionRight>
            <MotionRight>
              <p className="vision_text">
                To be a leading bank in the World, supporting the development of
                small businesses and financial inclusion around the world.
              </p>
            </MotionRight>
            <MotionX>
              <h3>Our Mission</h3>
            </MotionX>
            <MotionRight>
              <p className="vision_text">
                The mission of {name} is to contribute to the sustainable
                development of the international banking sector by providing
                responsible financial services and solutions to households and
                micro, small and medium enterprises, using internationally
                recognized best banking practices. We are committed to
                delivering value for our clients, shareholders, employees, and
                society at large. The mission is based on our values: integrity
                and openness, professionalism, commitment to customers, team
                work, and social and environmental responsibility.
              </p>
            </MotionRight>
          </div>
        </div>
        <div className="home_footer_sect">
          <div className="home_footer_inner_parent home_footer_inner_parent_one">
            <div>
              <img src={newsOne} alt="" />
            </div>
            <div className="new_content_sect home_footer_text_sect">
              <Motion>
                <p className="para_text">Home Mortgage loans</p>
              </Motion>
              <MotionRight>
                <h2>
                  The keys to your dream home are within reach — and we can help
                  you get them with affordable and flexible rates.
                </h2>
              </MotionRight>
              <MotionX>
                <p className="home_footer_date_text para_text">
                  December 14th, 2023 by Admin
                </p>
              </MotionX>
            </div>
          </div>
          <div className="">
            <img src={newsThree} alt="" />
          </div>
          <div className="home_footer_inner_parent home_footer_inner_parent_two">
            <div>
              <div className="home_footer_text_sect">
                <MotionRight>
                  <p className="para_text">PERSONAL NEEDS</p>
                </MotionRight>
                <Motion>
                  <h2>
                    Whether it's a sudden expense or just vacation to get away
                    from everything, {name} can make it happen.
                  </h2>
                </Motion>
                <MotionX>
                  <p className="home_footer_date_text para_text">
                    December 19th, 2023 by Admin
                  </p>
                </MotionX>
              </div>
            </div>
            <div>
              <img src={newsTwo} alt="" />
            </div>
          </div>
          <div className="home_footer_text_sect home_footer_text_sect_wrapper">
            <MotionRight>
              <p className="para_text">HSA</p>
            </MotionRight>
            <Motion>
              <h2>
                An HSA (Health Saving Account) helps employees save in advance
                for future medical expenses.
              </h2>
            </Motion>
            <MotionX>
              <p className="home_footer_date_text para_text">
                December 16th, 2023 by Admin
              </p>
            </MotionX>
          </div>
        </div>
      </section>
      <div id="hbagency_space_191298"></div>
      <Footer />
    </>
  );
};

export default Loading(Home);
